.ant-page-header {
  padding: 0;
}

.ant-notification svg {
  color: black !important;
}

.ant-layout-content {
  background: rgb(30, 30, 30) !important;
}

.ant-layout-content {
  background: #eeeeee;
  padding: 8px 20px 0 20px;
}
.ant-layout-footer {
  border-top: 1px solid #e2dfdfcc;
  padding: 6px 50px;
  background: transparent;
  color: #29392b;
}
.ant-layout-sider-zero-width-trigger {
  top: 0px;
}
.marginForm > .ant-form-item-control {
  margin-top: 6px;
}
#header {
  background: transparent;
  padding-right: 24px;
  padding-left: 0;
  position: absolute;
  right: 0px;
  z-index: 99;
}
#header .ant-popover-inner-content {
  padding: 0;
}
#header div.ant-popover-inner div.ant-card.ant-card-bordered {
  margin-top: 0;
}

.ant-page-header-compact .ant-page-header-heading-extra {
  display: grid;
}

.ant-page-header.ant-page-header-ghost {
  width: 100%;
  min-height: calc(100% - 39px);
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number.ant-col.ant-col-24 {
  width: 100%;
}
.compassCard > .ant-card-body {
  flex-wrap: wrap;
}

.pointTable .ant-table-row {
  cursor: pointer;
}

#compassImage {
  height: 280px;
  width: 280px;
  transition-duration: 0.5s;
  margin: 0 auto;
  display: block;
}
div.compassScales {
  top: -134px;
  left: 51%;
  position: absolute;
}
.compassCard > .ant-card-body {
  margin-top: 12px;
  padding: 0px !important;
}
span.firstScale,
span.secondScale,
span.thirdScale {
  display: block;
  position: relative;
}
span.firstScale {
  top: 31px;
}
span.secondScale {
  top: 44px;
}
span.thirdScale {
  top: 58px;
}

.ant-card-body {
  padding: 5px !important;
}

.ant-card-body > b {
  text-align: center;
  display: block;
  margin: 2px 0;
}

@media only screen and (max-width: 767px) {
  .ant-page-header-heading-left,
  .ant-page-header-heading-extra {
    margin: 4px auto !important;
  }
}

.ant-page-header-heading-extra {
  width: 80%;
}

.charging.blinkEmpty {
  animation: blinkEmpty 2s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

.charging.blinkQuarter {
  animation: blinkQuarter 2s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

.charging.blinkHalf {
  animation: blinkHalf 2s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

.charging.blinkThreeQuarters {
  animation: blinkThreeQuarters 2s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

.charging.blinkFull {
  animation: blinkFull 2s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

.blinkRed {
  animation: blinkRed 1s infinite;
  transition-duration: 0s;
  transition-delay: 0ms;
}

@keyframes blinkRed {
  0% {
    color: red;
  }
  49% {
    color: red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: red;
  }
}

@keyframes blinkEmpty {
  0% {
    color: #000;
  }
  25% {
    color: transparent;
  }
  50% {
    color: transparent;
  }
  75% {
    color: transparent;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkQuarter {
  0% {
    color: transparent;
  }
  25% {
    color: #000;
  }
  50% {
    color: transparent;
  }
  75% {
    color: transparent;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkHalf {
  0% {
    color: transparent;
  }
  25% {
    color: transparent;
  }
  50% {
    color: #000;
  }
  75% {
    color: transparent;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkThreeQuarters {
  0% {
    color: transparent;
  }
  25% {
    color: transparent;
  }
  50% {
    color: transparent;
  }
  75% {
    color: #000;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkFull {
  0% {
    color: transparent;
  }
  25% {
    color: transparent;
  }
  50% {
    color: transparent;
  }
  75% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}

div.ant-table-header {
  overflow: inherit !important;
}

.rinexTable .ant-table-container {
  overflow-x: scroll;
}

.ant-layout.ant-layout-has-sider {
  background: #2a2a2a !important;
}

.ant-table {
  overflow-y: auto;
}
