.App-eletron {
  text-align: center;
}

.App-eletron .button-container {
  display: flex;
  justify-content: center;
}

.App-eletron button {
  margin: 10px 10px;
  padding: 10px 20px;
  font-size: 150px;
  font-weight: bold;
  cursor: pointer;
  width: 100vh;
  height: 40vh;
}

.gnss {
  color: cornflowerblue;
}

.esp32 {
  color: rgb(182, 182, 48);
}

.incompletion-message {
  color: red;
  font-size: 40px;
}

.completion-message {
  color: #4caf50;
  font-size: 40px;
}

.again-button {
  margin: 10px 10px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  width: 100vh;
  height: 6rem;
}

.progress-container {
  margin-top: 20px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.progress-container span {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
}

.progress-bar {
  height: 30px;
  background-color: #4caf50;
  transition: width 0.5s;
}

.App-eletron button:hover {
  background-color: white !important;
}

.serial-ports-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.serial-ports-container span {
  font-size: 20px;
  color: white;
}

.serial-ports-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.serial-port-button {
  background-color: white;
  font-size: 30px;
  font-weight: bold;
  color: black;
  padding: 5px 5px;
  cursor: pointer;
  width: 30vh;
  height: 6rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.serial-port-button:hover {
  background-color: #26c6da !important;
}

.serial-port-button.selected {
  background-color: #00bcd4;
  color: white;
}

.App-eletron .button-container button.disabled {
  pointer-events: none;
}
